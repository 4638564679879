// 报表管理
const reportManage = [
  {
    path: '/sfa_center/attendance/attendance_finish',
    name: 'attendance_finish',
    component: () => import(/* webpackChunkName: "sfa_center/reports/attendance_finish" */ '../views/reports/attendance_finish'),
    meta: { title: '考勤任务完成报表' },
  },
  {
    path: '/sfa_center/reports/banquet_recommender',
    name: 'banquet_recommender',
    component: () => import(/* webpackChunkName: "sfa_center/reports/banquet_recommender" */ '../views/reports/banquet_recommender'),
    meta: { title: '宴席推荐人奖励费' },
  },
  {
    path: '/sfa_center/reports/banquet_present',
    name: 'banquet_present',
    component: () => import(/* webpackChunkName: "sfa_center/reports/banquet_present" */ '../views/reports/banquet_present'),
    meta: { title: '买赠' },
  },
  {
    path: '/sfa_center/reports/renovation_costs',
    name: 'renovation_costs',
    component: () => import(/* webpackChunkName: "sfa_center/reports/renovation_costs" */ '../views/reports/renovation_costs'),
    meta: { title: '装修费报表' },
  },
  {
    path: '/sfa_center/reports/promote_materials',
    name: 'promote_materials',
    component: () => import(/* webpackChunkName: "sfa_center/reports/promote_materials" */ '../views/reports/promote_materials'),
    meta: { title: '促销物料报表' },
  },
  {
    path: '/sfa_center/reports/traditional_media_fee',
    name: 'traditional_media_fee',
    component: () => import(/* webpackChunkName: "sfa_center/reports/traditional_media_fee" */ '../views/reports/traditional_media_fee'),
    meta: { title: '传统媒体报表' },
  },
  {
    path: '/sfa_center/reports/redemption_promotion',
    name: 'redemption_promotion',
    component: () => import(/* webpackChunkName: "sfa_center/reports/redemption_promotion" */ '../views/reports/redemption_promotion'),
    meta: { title: '兑奖促销报表' },
  },
  {
    path: '/sfa_center/reports/entry_fee',
    name: 'entry_fee',
    component: () => import(/* webpackChunkName: "sfa_center/reports/entry_fee" */ '../views/reports/entry_fee'),
    meta: { title: '进场费' },
  },
  {
    path: '/sfa_center/reports/out_door_ad',
    name: 'out_door_ad',
    component: () => import(/* webpackChunkName: "sfa_center/reports/out_door_ad" */ '../views/reports/out_door_ad'),
    meta: { title: '户外广告报表' },
  },
  {
    path: '/sfa_center/reports/customer_fee',
    name: 'customer_fee',
    component: () => import(/* webpackChunkName: "sfa_center/reports/customer_fee" */ '../views/reports/customer_fee'),
    meta: { title: '客情费用报表' },
  },
  {
    path: '/sfa_center/reports/travel_promotion',
    name: 'travel_promotion',
    component: () => import(/* webpackChunkName: "sfa_center/reports/travel_promotion" */ '../views/reports/travel_promotion'),
    meta: { title: '旅游促销报表' },
  },
  {
    path: '/sfa_center/reports/physical_promotion',
    name: 'physical_promotion',
    component: () => import(/* webpackChunkName: "sfa_center/reports/physical_promotion" */ '../views/reports/physical_promotion'),
    meta: { title: '实物促销报表' },
  },
  {
    path: '/sfa_center/reports/big_meeting',
    name: 'big_meeting',
    component: () => import(/* webpackChunkName: "sfa_center/reports/big_meeting" */ '../views/reports/big_meeting'),
    meta: { title: '大型会议报表' },
  },
  {
    path: '/sfa_center/reports/display_fees',
    name: 'display_fees',
    component: () => import(/* webpackChunkName: "sfa_center/reports/display_fees" */ '../views/reports/display_fees'),
    meta: { title: '陈列费报表' },
  },
  {
    path: '/sfa_center/reports/meeting_affair_charge',
    name: 'meeting_affair_charge',
    component: () => import(/* webpackChunkName: "sfa_center/reports/meeting_affair_charge" */ '../views/reports/meeting_affair_charge'),
    meta: { title: '会务费报表' },
  },
  {
    path: '/sfa_center/reports/package_amount_fee',
    name: 'package_amount_fee',
    component: () => import(/* webpackChunkName: "sfa_center/reports/package_amount_fee" */ '../views/reports/package_amount_fee'),
    meta: { title: '包量费报表' },
  },
  {
    path: '/sfa_center/reports/promote_employee_salaries',
    name: 'promote_employee_salaries',
    component: () => import(/* webpackChunkName: "sfa_center/reports/promote_employee_salaries" */ '../views/reports/promote_employee_salaries'),
    meta: { title: '促销员工工资报表' },
  },

];
/** @desc 考勤管理 */
const attendanceManage = [
  {
    path: '/sfa_center/attendance/rule',
    name: 'sfa_attendance_rule',
    component: () => import(/* webpackChunkName: "sfa_center/attendance/rule" */ '../views/attendance_manage/rule'),
    meta: { title: '考勤规则' },
  },
  {
    path: '/sfa_center/attendance/task',
    name: 'task',
    component: () => import(/* webpackChunkName: "sfa_center/attendance/task" */ '../views/attendance_manage/task/index.vue'),
    meta: { title: '考勤任务' },
  },
  {
    path: '/sfa_center/attendance/sign_group',
    name: 'sfa_attendance_signgroup',
    component: () => import(/* webpackChunkName: "sfa_center/attendance/sign_group" */ '../views/attendance_manage/sign_group'),
    meta: { title: '签到组' },
  },
  {
    path: '/sfa_center/attendance/business_trip_application',
    name: 'sfa_attendance_business_trip_application',
    component: () => import(/* webpackChunkName: "sfa_center/attendance/business_trip_application" */ '../views/attendance_manage/business_trip_application'),
    meta: { title: '出差申请明细报表' },
  },
  {
    path: '/sfa_center/attendance/abnormal_report_details',
    name: 'sfa_attendance_abnormal_report_details',
    component: () => import(/* webpackChunkName: "sfa_center/attendance/abnormal_report_details" */ '../views/attendance_manage/abnormal_report_details'),
    meta: { title: '异常报备申请明细报表' },
  },
  {
    path: '/sfa_center/attendance/weekday_adjust_summary',
    name: 'sfa_attendance_weekday_adjust_summary',
    component: () => import(/* webpackChunkName: "sfa_center/attendance/weekday_adjust_summary" */ '../views/attendance_manage/weekday_adjust_summary'),
    meta: { title: '工作日调整申请汇总报表' },
  },
  {
    path: '/sfa_center/attendance/weekday_adjust_details',
    name: 'sfa_attendance_weekday_adjust_details',
    component: () => import(/* webpackChunkName: "sfa_center/attendance/weekday_adjust_details" */ '../views/attendance_manage/weekday_adjust_details'),
    meta: { title: '工作日调整申请明细报表' },
  },
  {
    path: '/sfa_center/attendance/leave_summary',
    name: 'sfa_attendance_leave_summary',
    component: () => import(/* webpackChunkName: "sfa_center/attendance/leave_summary" */ '../views/attendance_manage/leave_summary'),
    meta: { title: '请假申请汇总报表' },
  },
  {
    path: '/sfa_center/attendance/leave_apply_details',
    name: 'sfa_attendance_leave_apply_details',
    component: () => import(/* webpackChunkName: "sfa_center/attendance/leave_apply_details" */ '../views/attendance_manage/leave_apply_details'),
    meta: { title: '请假申请明细报表' },
  },
  {
    path: '/sfa_center/attendance/monthly_attendance',
    name: 'sfa_attendance_monthly_attendance',
    component: () => import(/* webpackChunkName: "sfa_center/attendance/monthly_attendance" */ '../views/attendance_manage/monthly_attendance'),
    meta: { title: '月度考勤报表' },
  },
  {
    path: '/sfa_center/attendance/attendance_details',
    name: 'sfa_attendance_attendance_details',
    component: () => import(/* webpackChunkName: "sfa_center/attendance/attendance_details" */ '../views/attendance_manage/attendance_details'),
    meta: { title: '考勤明细报表' },
  },
  {
    path: '/sfa_center/attendance/check_in_details_report',
    name: 'sfa_attendance_check_in_details_report',
    component: () => import(/* webpackChunkName: "sfa_center/attendance/check_in_details_report" */ '../views/attendance_manage/check_in_details_report'),
    meta: { title: '签到明细报表' },
  },
  {
    path: '/sfa_center/attendance/calculate_salary_date',
    name: 'sfa_attendance_calculate_salary_date',
    component: () => import(/* webpackChunkName: "sfa_center/attendance/calculate_salary_date" */ '../views/attendance_manage/calculate_salary_date'),
    meta: { title: '计算薪资日期' },
  },
  {
    path: '/sfa_center/attendance/repair_clock_application',
    name: 'sfa_attendance_repair_clock_application',
    component: () => import(/* webpackChunkName: "sfa_center/attendance/repair_clock_application" */ '../views/attendance_manage/repair_clock_application'),
    meta: { title: '补打卡申请报表' },
  },
  {
    path: '/sfa_center/attendance/offApplyReport',
    name: 'sfa_attendance_offApplyReport',
    component: () => import(/* webpackChunkName: "sfa_center/attendance/offApplyReport" */ '../views/attendance_manage/offApplyReport'),
    meta: { title: '电商调休明细报表' },
  },
  {
    path: '/sfa_center/attendance/workOvertimeApplyReport',
    name: 'sfa_attendance_workOvertimeApplyReport',
    component: () => import(/* webpackChunkName: "sfa_center/attendance/workOvertimeApplyReport" */ '../views/attendance_manage/workOvertimeApplyReport'),
    meta: { title: '加班/值班明细报表' },
  },
];

/** @desc 任务管理 */
const taskManage = [
  {
    path: '/sfa_center/task/notice_manage',
    name: 'sfa_task_notice_manage',
    component: () => import(/* webpackChunkName: "sfa_center/task/notice_manage" */ '../views/task_manage/notice_manage'),
    meta: { title: '公告管理' },
  },
  {
    path: '/sfa_center/task/work_task',
    name: 'sfa_task_work_task',
    component: () => import(/* webpackChunkName: "sfa_center/task/work_task" */ '../views/task_manage/work_task'),
    meta: { title: '临时任务' },
  },
  {
    path: '/sfa_center/task/work_summary',
    name: 'sfa_task_work_summary',
    component: () => import(/* webpackChunkName: "sfa_center/task/work_summary" */ '../views/task_manage/work_summary'),
    meta: { title: '工作总结查询' },
  },
  {
    path: '/sfa_center/task/job_summary',
    name: 'sfa_task_job_summary',
    component: () => import(/* webpackChunkName: "sfa_center/task/job_summary" */ '../views/task_manage/job_summary'),
    meta: { title: '工作总结' },
  },
];

/** @desc 终端管理 */
const terminalManage = [{
  path: '/sfa_center/terminal_manage/terminal_list',
  name: 'sfaCenter_terminalManage_terminalList',
  component: () => import(/* webpackChunkName: "sfa_center/terminal_manage/terminal_list" */ '../views/terminal_manage/terminal_list'),
  meta: { title: '终端管理列表' },
}];

/** @desc 拜访管理 */
const visitManage = [
  {
    path: '/sfa_center/visit_manage/visit_controls',
    name: 'sfaCenter_visitManage_visitControls',
    component: () => import(/* webpackChunkName: "sfa_center/visit_manage/visit_controls" */ '../views/visit_manage/visit_controls'),
    meta: { title: '拜访控件' },
  },
  {
    path: '/sfa_center/visit_manage/visit_step_form',
    name: 'sfaCenter_visitManage_visitStepForm',
    component: () => import(/* webpackChunkName: "sfa_center/visit_manage/visit_step_form" */ '../views/visit_manage/visit_step_form'),
    meta: { title: '拜访步骤表单配置' },
  },
  {
    path: '/sfa_center/visit_manage/visit_step',
    name: 'sfaCenter_visitManage_visitStep',
    component: () => import(/* webpackChunkName: "sfa_center/visit_manage/visit_step" */ '../views/visit_manage/visit_step'),
    meta: { title: '拜访步骤' },
  },
  {
    path: '/sfa_center/visit_manage/line_group_management',
    name: 'sfaCenter_visitManage_lineGroupManagement',
    component: () => import(/* webpackChunkName: "sfa_center/visit_manage/line_group_management" */ '../views/visit_manage/line_group_management'),
    meta: { title: '线路组管理' },
  },
  {
    path: '/sfa_center/visit_manage/visit_plan_formulate',
    name: 'sfaCenter_visitManage_visitPlanFormulate',
    component: () => import(/* webpackChunkName: "sfa_center/visit_manage/visit_plan_formulate" */ '../views/visit_manage/visit_plan_formulate'),
    meta: { title: '拜访计划制定' },
  },
  {
    path: '/sfa_center/competitor_product/competitor_product_info',
    name: 'competitor_product_info',
    component: () => import(/* webpackChunkName: "sfa_center/competitor_product/competitor_product_info" */ '../views/competitor_product/competitor_product_info'),
    meta: { title: '商品信息查询' },
  },
  {
    path: '/sfa_center/competitor_product/competitor_product_management',
    name: 'competitor_product_management',
    component: () => import(/* webpackChunkName: "sfa_center/competitor_product/competitor_product_management" */ '../views/competitor_product/competitor_product_management'),
    meta: { title: '竞品商品管理' },
  },
  {
    path: '/sfa_center/visit_manage/dealer_visit_detail',
    name: 'sfaCenter_visitManage_dealerVisitDetail',
    component: () => import(/* webpackChunkName: "sfa_center/visit_manage/dealer_visit_detail" */ '../views/visit_manage/dealer_visit_detail'),
    meta: { title: '经销商拜访明细报表' },
  },
  {
    path: '/sfa_center/visit_manage/dealer_month_visit_detail',
    name: 'sfaCenter_visitManage_dealerMonthVisitDetail',
    component: () => import(/* webpackChunkName: "sfa_center/visit_manage/dealer_month_visit_detail" */ '../views/visit_manage/dealer_month_visit_detail'),
    meta: { title: '经销商月度拜访明细报表' },
  },
  {
    path: '/sfa_center/visit_manage/abnormal_eport',
    name: 'sfaCenter_visitManage_abnormalEport',
    component: () => import(/* webpackChunkName: "sfa_center/visit_manage/abnormal_eport" */ '../views/visit_manage/abnormal_eport'),
    meta: { title: '异常提报报表' },
  },
  {
    path: '/sfa_center/visit_manage/visit_activity_report',
    name: 'sfaCenter_visitManage_visitActivityReport',
    component: () => import(/* webpackChunkName: "sfa_center/visit_manage/visit_activity_report" */ '../views/visit_manage/visit_activity_report'),
    meta: { title: '拜访活动报表' },
  },
  {
    path: '/sfa_center/visit_manage/visit_plan_detail',
    name: 'sfaCenter_visitManage_visitPlanDetail',
    component: () => import(/* webpackChunkName: "sfa_center/visit_manage/visit_plan_detail" */ '../views/visit_manage/visit_plan_detail'),
    meta: { title: '拜访计划明细' },
  },
  {
    path: '/sfa_center/visit_manage/store_inspection',
    name: 'sfaCenter_visitManage_storeInspection',
    component: () => import(/* webpackChunkName: "sfa_center/visit_manage/store_inspection" */ '../views/visit_manage/store_inspection'),
    meta: { title: '店面检查报表' },
  },
  {
    path: '/sfa_center/visit_manage/inventory_detail',
    name: 'sfaCenter_visitManage_inventoryDetail',
    component: () => import(/* webpackChunkName: "sfa_center/visit_manage/inventory_detail" */ '../views/visit_manage/inventory_detail'),
    meta: { title: '费用库存明细报表' },
  },
  {
    path: '/sfa_center/visit_manage/display_activity_execution',
    name: 'sfaCenter_visitManage_displayInventoryDetail',
    component: () => import(/* webpackChunkName: "sfa_center/visit_manage/display_activity_execution" */ '../views/visit_manage/display_activity_execution'),
    meta: { title: '陈列活动执行报表' },
  },
  {
    path: '/sfa_center/visit_manage/activity_execution',
    name: 'sfaCenter_visitManage_activityExecution',
    component: () => import(/* webpackChunkName: "sfa_center/visit_manage/activity_execution" */ '../views/visit_manage/activity_execution'),
    meta: { title: '活动执行报表' },
  },
  {
    path: '/sfa_center/visit_manage/visit_completion_progress',
    name: 'sfaCenter_visitManage_visitCompletionProgress',
    component: () => import(/* webpackChunkName: "sfa_center/visit_manage/visit_completion_progress" */ '../views/visit_manage/visit_completion_progress'),
    meta: { title: '拜访完成进度报表' },
  },
  {
    path: '/sfa_center/visit_manage/visit_detail',
    name: 'sfaCenter_visitManage_visitDetail',
    component: () => import(/* webpackChunkName: "sfa_center/visit_manage/visit_detail" */ '../views/visit_manage/visit_detail'),
    meta: { title: '拜访明细报表' },
  },
  {
    path: '/sfa_center/visit_manage/visit_day_progress',
    name: 'sfaCenter_visitManage_visitDayProgress',
    component: () => import(/* webpackChunkName: "sfa_center/visit_manage/visit_day_progress" */ '../views/visit_manage/visit_progress_report/day_progress'),
    meta: { title: '拜访完成进度报表-日进度' },
  },
  {
    path: '/sfa_center/visit_manage/visit_month_progress',
    name: 'sfaCenter_visitManage_visitMonthProgress',
    component: () => import(/* webpackChunkName: "sfa_center/visit_manage/visit_month_progress" */ '../views/visit_manage/visit_progress_report/month_progress'),
    meta: { title: '拜访完成进度报表-月进度' },
  },
];

/** @desc 稽查管理 */
const inspectionManage = [{
  path: '/sfa_center/inspection_manage/inspection_person_area_config',
  name: 'sfaCenter_inspectionManage_inspectionPersonAreaConfig',
  component: () => import(/* webpackChunkName: "sfa_center/inspection_manage/inspection_person_area_config" */ '../views/inspection_manage/inspection_person_area_config'),
  meta: { title: '稽查人员稽查区域配置表' },
},
{
  path: '/sfa_center/inspection_manage/product_standard_channel_price',
  name: 'sfaCenter_inspectionManage_productStandardChannelprice',
  component: () => import(/* webpackChunkName: "sfa_center/inspection_manage/product_standard_channel_price" */ '../views/inspection_manage/product_standard_channel_price'),
  meta: { title: '产品标准渠道售价' },
},
];

/** @desc 文件资源管理 */
const fileResourceManage = [{
  path: '/sfa_center/file_resource_management/download_center',
  name: 'download_center',
  component: () => import(/* webpackChunkName: "sfa_center/file_resource_management/download_center" */ '../views/file_resource_management/download_center'),
  meta: { title: '文件中心' },
}];

/** @desc 订单管理 */
const orderManage = [{
  path: '/sfa_center/order_manage/order_detail',
  name: 'sfaCenter_orderManage_orderDetail',
  component: () => import(/* webpackChunkName: "sfa_center/order_manage/order_detail" */ '../views/order_manage/order_detail'),
  meta: { title: '订单明细报表' },
},
{
  path: '/sfa_center/order_manage/terminal_purchase_analysis',
  name: 'sfaCenter_orderManage_terminalPurchaseAnalysis',
  component: () => import(/* webpackChunkName: "sfa_center/order_manage/terminal_purchase_analysis" */ '../views/order_manage/terminal_purchase_analysis'),
  meta: { title: '终端进货量分析' },
},
{
  path: '/sfa_center/order_manage/directly_order_report',
  name: 'directly_order_report',
  component: () => import(/* webpackChunkName: "sfa_center/order_manage/directly_order_report" */ '../views/order_manage/directly_order_report'),
  meta: { title: '直营订单报表' },
},
{
  path: '/sfa_center/order_manage/directly_order_receive',
  name: 'directly_order_receive',
  component: () => import(/* webpackChunkName: "sfa_center/order_manage/directly_order_report" */ '../views/order_manage/directly_order_receive'),
  meta: { title: '直营订单收款报表' },
},
{
  path: '/sfa_center/order_manage/sales_statistics',
  name: 'sales_statistics',
  component: () => import(/* webpackChunkName: "sfa_center/order_manage/directly_order_report" */ '../views/order_manage/sales_statistics'),
  meta: { title: '出货任务统计报表' },
},
{
  path: '/sfa_center/order_manage/terminal_statistics',
  name: 'terminal_statistics',
  component: () => import(/* webpackChunkName: "sfa_center/order_manage/terminal_statistics" */ '../views/order_manage/terminal_statistics'),
  meta: { title: '终端统计报表' },
},
{
  path: '/sfa_center/order_manage/personnel_entry_report',
  name: 'personnel_entry_report',
  component: () => import(/* webpackChunkName: "sfa_center/order_manage/personnel_entry_report" */ '../views/order_manage/personnel_entry_report'),
  meta: { title: '人员录入报表' },
},
{
  path: '/sfa_center/order_manage/external_employees',
  name: 'external_employees',
  component: () => import(/* webpackChunkName: "sfa_center/order_manage/external_employees" */ '../views/order_manage/external_employees'),
  meta: { title: '外聘人员录入' },
},
];

/** @desc 活动管理 */
const activityTaskManage = [
  {
    path: '/sfa_center/activity/activity_task',
    name: 'sfa_activity_task',
    component: () => import(/* webpackChunkName: "sfa_center/activity/activity_task" */ '../views/activity/activity_task'),
    meta: { title: '活动任务管理' },
  },
  {
    path: '/sfa_center/activity/program_manage',
    name: 'sfa_program_manage',
    component: () => import(/* webpackChunkName: "sfa_center/activity/program_manage" */ '../views/activity/program_manage'),
    meta: { title: '方案管理' },
  },
  {
    path: '/sfa_center/activity/activity_program',
    name: 'sfa_activity_program',
    component: () => import(/* webpackChunkName: "sfa_center/activity/activity_program" */ '../views/activity/activity_program'),
    meta: { title: '方案活动管理' },
  },
  {
    path: '/sfa_center/activity/tpm_execute_report',
    name: 'sfa_activity_tpm_execute_report',
    component: () => import(/* webpackChunkName: "sfa_center/activity/tpm_execute_report" */ '../views/activity/tpm_execute_report'),
    meta: { title: 'TPM活动执行报表' },
  },
];
/** @desc 方案活动报表 */
const activityReport = [
  {
    path: '/sfa_center/activity/activity_report/electronic_deal_list',
    name: 'sfa_electronic_deal_list',
    component: () => import(/* webpackChunkName: "sfa_center/activity/activity_report/electronic_deal_list" */ '../views/activity/activity_report/electronic_deal_list'),
    meta: { title: '活动电子协议列表' },
  },
  {
    path: '/sfa_center/activity/activity_report/display_execute_detail',
    name: 'sfa_display_execute_detail',
    component: () => import(/* webpackChunkName: "sfa_center/activity/activity_report/display_execute_detail" */ '../views/activity/activity_report/display_execute_detail'),
    meta: { title: '陈列活动执行' },
  },
  {
    path: '/sfa_center/activity/activity_report/promotion_execute_detail',
    name: 'sfa_promotion_execute_detail',
    component: () => import(/* webpackChunkName: "sfa_center/activity/activity_report/promotion_execute_detail" */ '../views/activity/activity_report/promotion_execute_detail'),
    meta: { title: '促销活动执行' },
  },
  {
    path: '/sfa_center/activity/activity_report/activity_cash_detail',
    name: 'sfa_activity_cash_detail',
    component: () => import(/* webpackChunkName: "sfa_center/activity/activity_report/activity_cash_detail" */ '../views/activity/activity_report/activity_cash_detail'),
    meta: { title: ' 陈列活动兑付明细' },
  },
];
/** @desc 协访管理 */
const interviewPlanManage = [{
  path: '/sfa_center/interview/interview_plan',
  name: 'sfa_interview_plan',
  component: () => import(/* webpackChunkName: "sfa_center/interview/interview_plan" */ '../views/interview/interview_plan'),
  meta: { title: '协访计划制定' },
},
{
  path: '/sfa_center/interview/interview_detail_report',
  name: 'sfa_interview_detail_report',
  component: () => import(/* webpackChunkName: "sfa_center/interview/interview_detail_report" */ '../views/interview/interview_detail_report'),
  meta: { title: '协访明细报表' },
},
{
  path: '/sfa_center/interview/interview_comment_report',
  name: 'sfa_interview_comment_report',
  component: () => import(/* webpackChunkName: "sfa_center/interview/interview_detail_report" */ '../views/interview/interview_comment_report'),
  meta: { title: '协访评价报表' },
},
];

/** @desc 积分管理 */
const pointManage = [{
  path: '/sfa_center/point_manage/point_rule',
  name: 'sfaCenter_pointManage_pointRule',
  component: () => import(/* webpackChunkName: "sfa_center/point_manage/point_rule" */ '../views/point_manage/point_rule'),
  meta: { title: '积分规则' },
},
{
  path: '/sfa_center/point_manage/point_detail',
  name: 'sfaCenter_pointManage_pointDetail',
  component: () => import(/* webpackChunkName: "sfa_center/point_manage/point_detail" */ '../views/point_manage/point_detail'),
  meta: { title: '积分明细' },
},
{
  path: '/sfa_center/point_manage/point_ranking',
  name: 'sfaCenter_pointManage_pointRanking',
  component: () => import(/* webpackChunkName: "sfa_center/point_manage/point_ranking" */ '../views/point_manage/point_ranking'),
  meta: { title: '积分排名' },
},
];

/** @desc 绩效管理 */
const performanceManage = [{
  path: '/sfa_center/performance_manage/index_set',
  name: 'sfaCenter_performanceManage_indexSet',
  component: () => import(/* webpackChunkName: "sfa_center/performance_manage/index_set" */ '../views/performance_manage/index_set'),
  meta: { title: '指标设置' },
},
{
  path: '/sfa_center/performance_manage/performace_target_report',
  name: 'sfaCenter_performanceManage_performace_target_report',
  component: () => import(/* webpackChunkName: "sfa_center/performance_manage/performace_target_report" */ '../views/performance_manage/performace_target_report'),
  meta: { title: '绩效目标导入表' },
},
{
  path: '/sfa_center/performance_manage/performance_progress_table',
  name: 'sfaCenter_performanceManage_performance_progress_table',
  component: () => import(/* webpackChunkName: "sfa_center/performance_manage/performance_progress_table" */ '../views/performance_manage/performance_progress_table'),
  meta: { title: '绩效规则' },
},
];

export default [
  ...attendanceManage,
  ...taskManage,
  ...terminalManage,
  ...visitManage,
  ...inspectionManage,
  ...fileResourceManage,
  ...orderManage,
  ...pointManage,
  ...activityTaskManage,
  ...interviewPlanManage,
  ...performanceManage,
  ...activityReport,
  ...reportManage,
];
